import { computed, Signal, signal, WritableSignal } from "@angular/core";
import { ViewState } from "../../../core";
import { Reducer, Selector, State } from "./state.interface";

export class StateSignal<T extends ViewState, SubT extends T = T>
  implements State<T, SubT>
{
  private readonly signal: WritableSignal<T>;

  constructor(initialState: T) {
    this.signal = signal(initialState);
  }

  public assertType<Type extends T["$type"]>(
    $type: Type,
  ): asserts this is State<T, Extract<T, { $type: Type }>> {
    if (this.$value.$type !== $type) {
      throw new Error(
        `Expected state to be of type "${$type}" but was "${this.$value.$type}"`,
      );
    }
  }

  public get $value(): Readonly<SubT> {
    return this.signal() as unknown as Readonly<SubT>;
  }

  public set(state: T | Reducer<SubT, T>): void {
    if (typeof state === "function") {
      this.signal.set(state(this.$value));
    } else {
      this.signal.set(state);
    }
  }

  public selector<U>(selector: Selector<SubT, U>): Signal<U> {
    return computed(() => selector(this.$value));
  }
}
